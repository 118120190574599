import React from 'react';

function UrlList({ urls }) {
  return (
    <div className="url-list">
      <h2>List of URLs</h2>
      <ul>
        {urls.map(({ path, url }, index) => (
          <li key={index}>
            <strong>Path:</strong> <code>/{path}</code> <br />
            <strong>URL:</strong> <a href={`/${path}`}>{url}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UrlList;